<template>
  <div>
    <dashboard-page-title :showAddBtn="false">{{ pageTitle }}</dashboard-page-title>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="6" class="mb-3">
            <input-form :placeholder="$t('main.name_ar')" :label="$t('main.name_ar')" :name="$t('main.name_ar')" validate="required" v-model="item.name_ar" :disabled="disabled"/>
          </b-col>
          <b-col md="6" class="mb-3">
            <input-form :placeholder="$t('main.name_en')" :label="$t('main.name_en')" :name="$t('main.name_en')" validate="required" v-model="item.name_en" :disabled="disabled"/>
          </b-col>
          <b-col cols="12" v-if="!disabled">
            <b-button variant="primary" type="submit" :disabled="loadingSubmit">
              <span v-if="!loadingSubmit">{{ $t('main.save') }}</span>
              <template v-else>
                <spinner-loading class="d-inline"></spinner-loading>
                <span>{{ $t('main.loading') }}</span>
              </template>
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import globalAdd from '@/mixins/globalAdd'
import storeCategoriesServices from '../services/storeCategories'

export default {
  mixins: [globalAdd],
  mounted () {
    core.index()
  },
  props: { id: { type: [String, Number], default: '' } },
  data () {
    return {
      pageName: this.$route?.meta?.name || '',
      loadingSubmit: true,
      progressImage: 0,
      item: {
        name_ar: '',
        name_en: ''
      },
      countries: []
    }
  },
  methods: {
    getDetails () {
      storeCategoriesServices.getDetails(this.id).then(response => {
        const data = response?.data?.data
        this.item = {
          name_ar: data?.name_ar || '',
          name_en: data?.name_en || ''
        }
        this.loadingSubmit = false
      })
    },
    saveImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressImage = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.item.flag = res.data
        this.showSuccessUploadFile()
      })
    },
    onSubmit () {
      this.loadingSubmit = true
      if (this.id) {
        storeCategoriesServices.update(this.id, this.item).then(res => {
          core.showSnackbar('success', this.$t('main.updatedSuccessfully'))
          this.loadingSubmit = false
          this.$router.push({ name: 'storeCategories' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      } else {
        storeCategoriesServices.add(this.item).then(res => {
          core.showSnackbar('success', this.$t('main.addedSuccessfully'))
          this.loadingSubmit = false
          this.$router.push({ name: 'storeCategories' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      }
    }
  },
  computed: {
    pageTitle () {
      switch (this.pageName) {
        case 'storeCategories.add':
          return this.$t('storeCategories.add')
        case 'storeCategories.update':
          return this.$t('storeCategories.update')
        default:
          return this.$t('storeCategories.details')
      }
    },
    disabled () {
      switch (this.pageName) {
        case 'storeCategories.add':
        case 'storeCategories.update':
          return false
        default:
          return true
      }
    }
  },
  created () {
    if (this.id) {
      console.log('this.id', this.id)
      this.getDetails()
    } else {
      this.loadingSubmit = false
    }
  }
}
</script>
